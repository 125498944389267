.mdc-line-ripple {
  display: none;
}

.mat-mdc-form-field {
  overflow: hidden;

  width: 100%;

  border: 1px solid $border-grey-light;
  border-radius: $border-radius;

  transition: border-color 0.3s ease-in;
}

.mat-mdc-text-field-wrapper {
  height: 78px !important;
  padding: 0 28px !important;
  background-color: transparent !important;

  @include media(desktop) {
    height: 66px !important;
    padding: 0 20px !important;
  }
}

.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: calc(80px / 2) !important; // высота input'a

  @include media(desktop) {
    top: calc(68px / 2) !important;
  }
}

.mdc-floating-label {
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 25px !important;
  color: $text-secondary !important;

  transition:
    transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
    color 0.3s ease-in !important;

  @include media(desktop) {
    font-size: 14px !important;
    line-height: 21px !important;
  }
}

.mat-mdc-form-field-infix {
  padding: 36px 0 15px !important;

  @include media(desktop) {
    padding: 30px 0 14px !important;
  }
}

.mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 29px !important;
  color: $text-base !important;

  @include media(desktop) {
    font-size: 16px !important;
    line-height: 24px !important;
  }
}

.mat-form-field-readonly {
  pointer-events: none !important;

  label.mdc-floating-label.mat-mdc-floating-label {
    pointer-events: none !important;
    top: 50% !important;
  }

  &_empty {
    label.mdc-floating-label.mat-mdc-floating-label {
      transform: translateY(-50%) !important;
    }
  }
}

.mat-mdc-form-field.ng-invalid.ng-dirty {
  border-color: $border-red;

  .mdc-floating-label {
    color: $text-red !important;
  }
}

.mat-mdc-input-element {
  caret-color: $text-base !important;
}

.mat-mdc-form-field-icon-suffix {
  svg-icon {
    cursor: pointer;
  }
}
