.expocab-textarea {
  .mat-mdc-text-field-wrapper {
    height: 214px !important;
  }

  .mat-mdc-form-field-flex,
  .mat-mdc-form-field-infix {
    height: 100%;
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 43px !important;

    @include media(desktop) {
      top: 38px !important;
    }
  }

  .mat-mdc-form-field-infix {
    padding-top: 46px !important;

    @include media(desktop) {
      padding-top: 42px !important;
    }
  }

  textarea {
    resize: none;
    height: 100% !important;
  }

  .mat-mdc-form-field-icon-suffix {
    align-self: flex-start;
    margin-top: 28px;
  }
}
