.expocab-input-tooltip {
  @extend %tooltip;

  right: 37%;

  .mdc-tooltip__surface {
    max-width: 211px;

    @include media(desktop) {
      max-width: 166px;
    }
  }
}

.expocab-toggle-tooltip {
  @extend %tooltip;

  right: 30%;

  .mdc-tooltip__surface {
    max-width: 163px;

    @include media(desktop) {
      max-width: 128px;
    }
  }
}

%tooltip {
  bottom: 15%;

  &::after {
    content: '';

    position: absolute;
    right: 20px;
    bottom: -8px;
    transform: rotate(180deg);

    width: 0;
    height: 0;

    border-color: transparent transparent $text-base transparent;
    border-style: solid;
    border-width: 0 8px 8px;

    @include media(desktop) {
      right: 16px;
    }
  }

  .mdc-tooltip__surface {
    padding: 10px 18px 12px;

    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 22px !important;
    text-align: left;

    background-color: $text-base !important;
    border-radius: $border-radius !important;

    @include media(desktop) {
      padding: 8px 14px 10px;
    }

    @include media(desktop) {
      font-size: 11px !important;
      line-height: 18px !important;
    }
  }
}
