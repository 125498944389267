.app-container {
  @apply px-7 2xl:px-10;
}

.expocab-vertical-block {
  & + & {
    margin-top: $gap-lg;

    @include media(desktop) {
      margin-top: $gap-md;
    }
  }
}
