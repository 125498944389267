/* stylelint-disable scale-unlimited/declaration-strict-value */
$font-path: '/assets/fonts';

/* ----- GOLOS ----- */
@font-face {
  font-family: 'Golos Text';
  font-weight: 400;
  font-style: normal;
  src: url('#{$font-path}/Golos/GolosText-Regular.eot');
  src:
    local('Golos Text Regular'),
    local('GolosText-Regular'),
    url('#{$font-path}/Golos/GolosText-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Golos/GolosText-Regular.woff2') format('woff2'),
    url('#{$font-path}/Golos/GolosText-Regular.woff') format('woff'),
    url('#{$font-path}/Golos/GolosText-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Golos Text';
  font-weight: 500;
  font-style: normal;
  src: url('#{$font-path}/Golos/GolosText-Medium.eot');
  src:
    local('Golos Text Medium'),
    local('GolosText-Medium'),
    url('#{$font-path}/Golos/GolosText-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Golos/GolosText-Medium.woff2') format('woff2'),
    url('#{$font-path}/Golos/GolosText-Medium.woff') format('woff'),
    url('#{$font-path}/Golos/GolosText-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Golos Text';
  font-weight: 600;
  font-style: normal;
  src: url('#{$font-path}/Golos/GolosText-DemiBold.eot');
  src:
    local('Golos Text DemiBold'),
    local('GolosText-DemiBold'),
    url('#{$font-path}/Golos/GolosText-DemiBold.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Golos/GolosText-DemiBold.woff2') format('woff2'),
    url('#{$font-path}/Golos/GolosText-DemiBold.woff') format('woff'),
    url('#{$font-path}/Golos/GolosText-DemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Golos Text';
  font-weight: 700;
  font-style: normal;
  src: url('#{$font-path}/Golos/GolosText-Bold.eot');
  src:
    local('Golos Text Bold'),
    local('GolosText-Bold'),
    url('#{$font-path}/Golos/GolosText-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Golos/GolosText-Bold.woff2') format('woff2'),
    url('#{$font-path}/Golos/GolosText-Bold.woff') format('woff'),
    url('#{$font-path}/Golos/GolosText-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Golos Text';
  font-weight: 900;
  font-style: normal;
  src: url('#{$font-path}/Golos/GolosText-Black.eot');
  src:
    local('Golos Text Black'),
    local('GolosText-Black'),
    url('#{$font-path}/Golos/GolosText-Black.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Golos/GolosText-Black.woff2') format('woff2'),
    url('#{$font-path}/Golos/GolosText-Black.woff') format('woff'),
    url('#{$font-path}/Golos/GolosText-Black.ttf') format('truetype');
}
