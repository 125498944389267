@import 'node_modules/normalize.css/normalize.css';
@import 'style-utils';

*,
::after,
::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  scrollbar-gutter: stable;
  height: 100%;
}

html {
  scroll-behavior: smooth;
  font-family: $golos;
}

@supports not selector(::-webkit-scrollbar) {
  html {
    scrollbar-color: $text-secondary $divider-grey;
    scrollbar-width: thin;
  }
}

body {
  font-family: $golos !important;
  -webkit-font-smoothing: antialiased;
  color: $text-base !important;
  background-color: $bg-grey !important;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: $divider-grey;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $text-secondary;
    border-radius: 10px;
  }
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

button {
  cursor: pointer;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-user-select: none;
  user-select: none;

  background: none;
  border: none;
  outline: none;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

svg-icon {
  display: inline-block;
}

svg-icon,
svg-icon > svg {
  @include icon-size(24px);

  @include media(desktop) {
    @include icon-size(18px);
  }
}
