@import 'style-utils';

.expocab-h1-heading {
  @include expocab-h1-heading-lg;

  @include media(desktop) {
    @include expocab-h1-heading-md;
  }
}

.expocab-h2-heading {
  @include expocab-h2-heading-lg;

  @include media(desktop) {
    @include expocab-h2-heading-md;
  }
}

.expocab-h3-heading {
  @include expocab-h3-heading-lg;

  @include media(desktop) {
    @include expocab-h3-heading-md;
  }
}

.expocab-h4-heading {
  @include expocab-h4-heading-lg;

  @include media(desktop) {
    @include expocab-h4-heading-md;
  }
}

.expocab-h5-heading {
  @include expocab-h5-heading-lg;

  @include media(desktop) {
    @include expocab-h5-heading-md;
  }
}

.expocab-text-big {
  @include expocab-text-big-lg;

  @include media(desktop) {
    @include expocab-text-big-md;
  }
}

.expocab-text-medium {
  @include expocab-text-medium-lg;

  @include media(desktop) {
    @include expocab-text-medium-md;
  }
}

.expocab-text-base {
  @include expocab-text-base-lg;

  @include media(desktop) {
    @include expocab-text-base-md;
  }
}

.expocab-text-small {
  @include expocab-text-small-lg;

  @include media(desktop) {
    @include expocab-text-small-md;
  }
}

.expocab-text-xs {
  @include expocab-text-xs-lg;

  @include media(desktop) {
    @include expocab-text-xs-md;
  }
}

.expocab-button-big {
  @include expocab-button-big-lg;

  @include media(desktop) {
    @include expocab-button-big-md;
  }
}

.expocab-button-small {
  @include expocab-button-small-lg;

  @include media(desktop) {
    @include expocab-button-small-md;
  }
}
