@mixin expocab-h1-heading-lg {
  font-size: 58px;
  font-weight: 500;
  line-height: 70px;
}

@mixin expocab-h1-heading-md {
  font-size: 38px;
  font-weight: 500;
  line-height: 50px;
}

@mixin expocab-h2-heading-lg {
  font-size: 38px;
  font-weight: 500;
  line-height: 50px;
}

@mixin expocab-h2-heading-md {
  font-size: 28px;
  font-weight: 500;
  line-height: 38px;
}

@mixin expocab-h3-heading-lg {
  font-size: 30px;
  font-weight: 500;
  line-height: 38px;
}

@mixin expocab-h3-heading-md {
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
}

@mixin expocab-h4-heading-lg {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}

@mixin expocab-h4-heading-md {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
}

@mixin expocab-h5-heading-lg {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}

@mixin expocab-h5-heading-md {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

@mixin expocab-text-big-lg {
  font-size: 20px;
  font-weight: 400;
  line-height: 29px;
}

@mixin expocab-text-big-md {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

@mixin expocab-text-medium-lg {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
}

@mixin expocab-text-medium-md {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

@mixin expocab-text-base-lg {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

@mixin expocab-text-base-md {
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
}

@mixin expocab-text-small-lg {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

@mixin expocab-text-small-md {
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
}

@mixin expocab-text-xs-lg {
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
}

@mixin expocab-text-xs-md {
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
}

@mixin expocab-button-big-lg {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
}

@mixin expocab-button-big-md {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

@mixin expocab-button-small-lg {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

@mixin expocab-button-small-md {
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
}
