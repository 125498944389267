@import 'style-utils';

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.d-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

.no-pointer {
  pointer-events: none;
}

.text-red {
  color: $text-red;
}

.fw500 {
  font-weight: 500 !important;
}
